@import "./reset";
@import "./valiables";

*{
	box-sizing: border-box;
}
*:focus {
	outline: none;
  }

html,body{
	font-size: 62.5%; /*base font-size 10px*/
    font-family: 'ヒラギノ角ゴシック','Hiragino Sans',YuGothic,'Yu Gothic',sans-serif;
	font-weight: 400;
	color: #000000;
	line-height: 1;
}

body{
	@include fontResize (10,1280,1280,768);
	@include SP{
		@include fontResize (10,375,767);
	}
}
body.hidden{
	overflow: hidden;
}

a{
	text-decoration: none;
	color: #000000;
	cursor: pointer;
}

img{
	vertical-align: top;
	width:100%;
	max-width: 100%;
	height: auto;
}

.pc{
	@include SP{
		display: none!important;
	}
}

.sp{
	@include PC{
		display: none!important;
	}
}

.hover{
	transition: opacity .3s;
	&:hover{
		@include PC{
			opacity: .7;
		}
	}
}

main{
	@include PC{
		padding-top: 99px;
	}
	@include SP{
		padding-top: (59em/10);
	}
}

//header

header{
	background-color: #000000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99;
	.header_wrapper{
		display: flex;
		justify-content: space-between;
		position: relative;
		@include PC{
			align-items: flex-end;
			height: 100px;
		}
		@include SP{
			align-items: center;
			height: (60em/10);
		}
	}
	h1{
		width: 105px;
		padding:0 0 18px 58px;
		box-sizing: content-box;
		@include SP{
			width: (80em/10);
			padding:0 0 0 (15em/10);
		}
	}
	.g_nav{
		@include PC{
			display: flex;
			align-items: flex-end;
		}
		@include SP{
			position: fixed;
			top: (60em/10);
			width: 100%;
			height: calc(100vh - (60em/10));
			background-color: #000;
			padding: (40em/10) (15em/10);
			opacity: 0;
			transition: opacity .3s;
			pointer-events: none;
		}
		.header_nav{
			@include PC{
				display: flex;
				padding:0 24px 18px 0;
			}
			li{
				&:not(:last-child){
					@include PC{
						margin-right: 34px;
					}
				}
				@include SP{
					border-bottom: 1px solid #ccc;
				}
				a{
					font-size: 1.4rem;
					color: #fff;
					position: relative;
					padding-left: 26px;
					@include SP{
						@include fontResize(18,375,767);
						display: block;
						width: 100%;
						padding: (20em/16) 0  (20em/16)  (40em/16);
					}
					&::before{
						content: '';
						display: block;
						width: 24px;
						padding-bottom: 27px;
						background-image: url(/assets/img/common/light-ico.svg);
						background-size: contain;
						background-repeat: no-repeat;
						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
						margin-top: -5px;
						@include SP{
							width: (36em/16);
							padding-bottom: (40em/16);
						}
					}
					&:hover{
						@include PC{
							color: #FFF000;
						}
						&::before{
							@include PC{
								background-image: url(/assets/img/common/light-ico-active.svg);
							}
						}
					}
					&.current{
						color: #FFF000;
						&::before{
							background-image: url(/assets/img/common/light-ico-active.svg);
						}
					}
				}
			}
		}
		.contact_btn{
			a{
				display: block;
				color: #fff;
				background-color: $baseBlue;
				text-align: center;
				position: relative;
				@include PC{
					font-size: 1.4rem;
					width: 100px;
					height: 100px;
				}
				@include SP{
					border-radius: (4em/18);
					@include fontResize(18,375,767);
					padding: (20em/18);
					margin-top: (40em/18);
				}
				span{
					@include PC{
						display: block;
						width: 100%;
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
						bottom: 18px;
					}
				}
			}
		}
	}
	.hamburger{
		position: relative;
		z-index: 101;
		width: (60em/10);
		height: (60em/10);
		span{
			display: block;
			margin: 0;
			border: none;
			width: (30em/10);
			height: 3px;
			background: #fff;
			transform-origin:center;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			transition: .3s;
			&:nth-child(2){
				margin-top: -1em;
			}
			&:last-child{
				margin-top: 1em;
			}
		}
	}
	&.active{
		.g_nav{
			@include SP{
				opacity: 1;
				pointer-events: auto;
			}
		}
		.hamburger{
			span{
				transform: translate(-50%,-50%) rotate(135deg);
				&:nth-child(2){
					opacity: 0;
				}
				&:last-child{
					margin-top: 0;
					transform: translate(-50%,-50%) rotate(-135deg);
				}
			}
		}
	}
}

footer{
	background-color: #000000;
	@include SP{
		margin-bottom: (66em/10);
	}
	.footer_wrap{
		max-width: 1280px;
		margin: 0 auto;
		display: flex;
		padding-bottom: 72px;
		@include CUSTOM(1279){
			display: block;
		}
		@include SP{
			padding:0 (15em/10) (60em/10);
		}
		.footer_info{
			padding-top: 38px;
			margin-right: (136em/10);
			@include CUSTOM(1279){
				margin-right: 0;
				text-align: center;
			}
			@include SP{
				padding-top: (40em/10);
			}
			.logo{
				@include PC{
					width: 440px;
					margin-left: (58em/10);
				}
				@include CUSTOM(1279){
					margin: 0 auto;
				}
			}
			.address{
				color: #fff;
				margin: 22px 0 25px (177em/10);
				@include CUSTOM(1279){
					margin-left: 0;
					justify-content: center;
				}
				@include SP{
					margin: (20em/10) 0 (20em/10);
				}
				span{
					font-size: 1.6rem;
					@include SP{
						@include fontResize(16,375,767);
					}
				}
			}
			.sns{
				display: flex;
				margin-left: (177em/10);
				@include CUSTOM(1279){
					margin-left: 0;
					justify-content: center;
				}
				li{
					width: 36px;
					@include SP{
						@include fontResize(36,375,767);
					}
					&:first-child{
						margin-right: 5px;
						@include SP{
							margin-right: (10em/36);
						}
					}
				}
			}
		}
		.footer_links{
			padding-top: 130px;
			@include PC{
				display: flex;
				flex-grow: 1;
			}
			@include CUSTOM(1279){
				padding-top: 50px;
				justify-content: center;
			}
			@include SP{
				padding-top: (40em/10);
			}
			dl{
				white-space: nowrap;
				font-size: 1.5rem;
				@include SP{
					@include fontResize(15,375,767);
				}
				&:not(:last-child){
					@include PC{
						margin-right: (50em/10);
					}
				}
				dt{
					@include PC{
						margin-bottom: 30px;
					}
					@include SP{
						position: relative;
						border-bottom: 1px solid #ccc;
					}
					&::before{
						@include SP{
							content: '';
							display: block;
							width: 2px;
							height: 1.3em;
							background-color: #ccc;
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							right: (20em/15);
							transition: transform .3s;
						}
					}
					&::after{
						@include SP{
							content: '';
							display: block;
							width: (20em/15);
							height: 2px;
							background-color: #ccc;
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							right: (11em/15);
						}
					}
					a{
						color: #fff;
						@include SP{
							display: block;
							width: 100%;
							height: 100%;
							padding: (20em/15);
						}
					}
				}
				dd{
					@include SP{
						margin-left: (10em/15);
						display: none;
					}
					&:last-child{
						@include SP{
							margin-bottom: (30em/15);
						}
					}
					p{
						&:not(:last-child){
							@include PC{
								margin-bottom: 10px;
							}
						}
						@include SP{
							@include fontResize(12,375,767);
							border-bottom: 1px solid #ccc;
						}
						a{
							color: #fff;
							@include SP{
								display: block;
								width: 100%;
								height: 100%;
								padding: (15em/12) 0 (15em/12) (20em/12);
							}
						}
					}
				}
				&:last-child{
					dd{
						&:last-child{
							@include SP{
								margin-bottom: 0;
							}
						}
					}
				}
				&.active{
					dt{
						@include PC{
							margin-bottom: 30px;
						}
						@include SP{
							position: relative;
							border-bottom: 1px solid #ccc;
						}
						&::before{
							@include SP{
								transform: translateY(-50%) rotate(90deg);
							}
						}
					}
				}
			}
		}
	}
	.copyright{
		text-align: center;
		color: #fff;
		font-size: 1.5rem;
		padding-bottom: 28px;
		@include SP{
			@include fontResize(12,375,767);
			padding-bottom: (30em/12);
			line-height: 1.6;
		}
	}
	.fixed_footer{
		position: fixed;
		z-index: 98;
		padding-bottom: env(safe-area-inset-bottom);
		bottom: 0;
		background-color: #ffffff;
		width: 100%;
		display: flex;
		box-shadow: 0 -2px 4px #ccc;
		.icon_wrap{
			height: 100%;
			width: calc(100% / 2);
			text-align: center;
			position: relative;
			&:first-child{
				&::before{
					content: '';
					display: block;
					width: 1px;
					height: (40em/10);
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 0;
					background-color: #b4b4b4;
				}
			}
			a{
				display: block;
				width: 100%;
				height: 100%;
				padding: (10em/10) 0;
				.box{
					.icon{
						margin: 0 auto;
						&.tel{
							width: (30em/10);
						}
						&.mail{
							width: (30em/10);
						}
					}
					p{
						color: #464646;
						margin-top: (10em/20);
						@include fontResize (20,640,767);
					}
				}
			}
		}
	}
}

#contact-us{
	margin-top: (100em/10);
	padding-bottom: (22em/10);
	@include SP{
		padding-bottom: (40em/10);
		margin-top: (80em/10);
	}
	h2{
		text-align: center;
		@include fontResize (30,1280,1280,768);
		position: relative;
		@include SP{
			@include fontResize (24,375,767);
		}
		&::before{
			content: '';
			display: block;
			width: 100%;
			padding-bottom: (3em/30);
			background-color: #000;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
		}
		&::after{
			content: 'お問い合わせ';
			@include fontResize (14,1280,1280,768);
			position: absolute;
			top: (-20em/14);
			left: 50%;
			transform: translateX(-50%);
			@include SP{
				top: (-20em/16);
				@include fontResize (14,375,767);
			}
		}
		span{
			position: relative;
			z-index: 2;
			display: inline-block;
			padding: 0 (50em/30);
			background-color: #fff;
			&::before{
				content: '';
				display: block;
				width: (21em/30);
				padding-bottom: (13em/30);
				background-image: url(/assets/img/top/socket01.png);
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				top: 50%;
				left: -2px;
				transform: translateY(-50%);
			}
			&::after{
				content: '';
				display: block;
				width: (21em/30);
				padding-bottom: (13em/30);
				background-image: url(/assets/img/top/socket02.png);
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				top: 50%;
				right: -2px;
				transform: translateY(-50%);
			}
		}
	}
	.contact_wrap{
		max-width: 1280px;
		@include PC{
			margin: (54em/10) auto 0;
			display: flex;
			justify-content: center;
		}
		@include SP{
			margin: (35em/10) auto 0;
		}
		.contact_list{
			@include SP{
				padding: 0 (15em/10);
			}
			&:first-child{
				@include PC{
					margin-right: (30em/10);
				}
				@include SP{
					margin-bottom: (15em/10);
				}
			}
			a{
				display: flex;
				align-items: center;
				border: 1px solid #9FA0A0;
				@include PC{
					width: (490em/10);
					padding: (45em/10) 0 (45em/10) (38em/10);
				}
				@include SP{
					padding: (15em/10) 0 (15em/10) (15em/10);
				}
				.contact_icon{
					width: (69em/10);
					padding-bottom: (69em/10);
					margin-right: (20em/10);
					background-image: url(/assets/img/common/mail.svg);
					background-size: contain;
					background-repeat: no-repeat;
					transition: .3s;
					@include SP{
						width: (49em/10);
						padding-bottom: (49em/10);
						margin-right: (20em/10);
					}
					&.tel{
						background-image: url(/assets/img/common/tel.svg);
					}
				}
				&:hover{
					.contact_icon{
						@include PC{
							background-image: url(/assets/img/common/mail-hover.svg);
						}
						&.tel{
							@include PC{
								background-image: url(/assets/img/common/tel-hover.svg);
							}
						}
					}
				}
				.contact_text{
					.mail{
						@include fontResize (27,1280,1280,768);
						font-weight: bold;
						@include SP{
							@include fontResize (20,375,767);
						}
					}
					.mail_time{
						margin-top: (10em/23);
						@include fontResize (23,1280,1280,768);
						font-weight: bold;
						@include SP{
							margin-top: (5em/16);
							@include fontResize (16,375,767);
						}
					}
					.tel{
						@include fontResize (18,1280,1280,768);
						font-weight: bold;
						@include SP{
							@include fontResize (14,375,767);
						}
						span{
							@include fontResize (13,1280,1280,768);
							@include SP{
								@include fontResize (11,375,767);
							}
						}
					}
					.tel_num{
						margin-top: (10em/37);
						@include fontResize (37,1280,1280,768);
						font-weight: bold;
						@include SP{
							margin-top: (5em/24);
							@include fontResize (24,375,767);
						}
					}
				}
			}
		}
	}
}

#banner{
	background-color: $baseBlue;
	ul{
		display: flex;
		@include PC{
			max-width: 1280px;
			margin: 0 auto;
			padding: (48em/10) 0 (35em/10);
			display: flex;
			justify-content: center;
		}
		@include SP{
			flex-wrap: wrap;
			padding: (40em/10) (15em/10)  (20em/10);
		}
		li{
			@include PC{
				width: (223em/10);
			}
			@include SP{
				width: (165/345)*100%;
			}
			&:not(:last-child){
				@include PC{
					margin-right: (40em/10);
				}
				@include SP{
					margin-bottom: (20em/10);
				}
			}
			&:nth-child(2n){
				@include SP{
					margin-left: (15/345)*100%;
				}
			}
			p{
				color: #fff;
				@include fontResize (16,1280,1280,768);
				margin-top: (10em/16);
				@include SP{
					margin-top: (5em/12);
					@include fontResize (12,375,767);
				}
				span{
					display: inline-block;
					padding-right: (26em/16);
					position: relative;
					@include SP{
						padding-right: (16em/12);
					}
					&::before{
						content: '';
						display: block;
						width: (19em/16);
						height: (19em/16);
						background-image: url(/assets/img/common/link-ico.svg);
						background-size: contain;
						background-repeat: no-repeat;
						position: absolute;
						top:50%;
						right: 0;
						transform: translateY(-50%);
						@include SP{
							width: (12em/12);
							height: (12em/12);
						}
					}
				}
			}
		}
	}
}

#company_about{
	.about_lists{
		opacity: 1;
		transform: translateY(0);
		max-width: 1280px;
		@include PC{
			display: flex;
			flex-wrap: wrap;
			padding: 0 (140em/10) 0 (160em/10);
			margin: 0 auto;
		}
		@include SP{
			padding: 0 (15em/10);
		}
		li{
			opacity: 0;
			transition:opacity 0.5s ease-in 0.0s;
			&:nth-child(n+4){
				@include PC{
					margin-top: (26em/10);
				}
			}
			&:not(:nth-child(3n)){
				@include PC{
					margin-right: (24em/10);
				}
			}
			&:not(:last-child){
				@include SP{
					margin-bottom: (30em/10);
				}
			}
			a{
				display: block;
				width: (304em/10);
				padding-bottom: (235/304)*100%;
				position: relative;
				transition: .3s;
				@include SP{
					width: 100%;
				}
				&::before{
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					position: absolute;
					z-index: 1;
					background-image: url(/assets/img/top/frame02.svg);
					background-size: contain;
					background-repeat: no-repeat;
				}
				&::after{
					content: '';
					display: block;
					width: 96%;
					height: (219/235)*100%;
					top: (15em/10);
					margin-left: auto;
					right: 0;
					position: absolute;
					z-index: 0;
					opacity: 0;
					background: linear-gradient(to bottom, #ccc 0%, #fff 60%);
					transition: opacity .3s;
				}
				.about_image{
					width: (279em/10);
					position: absolute;
					z-index: 3;
					top: (65em/10);
					left: (11em/10);
					@include SP{
						top: (72em/10);
						width: (322em/10);
					}
				}
				.about_name{
					font-weight: bold;
					@include fontResize (18,1280,1280,768);
					line-height: (24/18);
					position: absolute;
					z-index: 3;
					top: (30em/18);
					left: (36em/18);
					@include SP{
						@include fontResize (20,375,767);
						top: (30em/20);
						left: (36em/20);
					}
				}
				.about_more{
					@include fontResize (13,1280,1280,768);
					padding-right: (26em/13);
					position: absolute;
					z-index: 3;
					top: (40em/13);
					left: (242em/13);
					@include SP{
						@include fontResize (14,375,767);
						top: (43em/14);
						left: (270em/14);
					}
					&::before{
						content: '';
						display: block;
						width: (24em/13);
						padding-bottom: (27em/13);
						background-image: url(/assets/img/common/light-ico-bk.svg);
						background-size: contain;
						background-repeat: no-repeat;
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
						margin-top: -5px;
						@include SP{
							width: (24em/14);
							padding-bottom: (27em/14);
							margin-top: -(5em/14);
						}
					}
				}
				&:hover{
					&:after{
						@include PC{
							opacity: 1;
						}
					}
					.about_more{
						@include PC{
							color: #FFF000;
						}
						&::before{
							@include PC{
								background-image: url(/assets/img/common/light-ico-active.svg);
							}
						}
					}
				}
				&.current{
					pointer-events: none;
					opacity: .5;
					// &:after{
					// 	@include PC{
					// 		opacity: 1;
					// 	}
					// }
					.about_more{
						// @include PC{
						// 	color: #FFF000;
						// }
						// &::before{
						// 	@include PC{
						// 		background-image: url(/assets/img/common/light-ico-active.svg);
						// 	}
						// }
					}
				}
			}
		}
		&.magic{
			li{
				opacity: 1;
				&:nth-child(2){
					transition-delay: .1s;
				}
				&:nth-child(3){
					transition-delay: .2s;
				}
				&:nth-child(4){
					transition-delay: .3s;
				}
				&:nth-child(5){
					transition-delay: .4s;
				}
				&:nth-child(6){
					transition-delay: .5s;
				}
			}
		}
	}
}

.scrollmagic{
	opacity: 0;
	transform: translateY(20px);
	transition: transform 0.5s ease-in 0.0s, opacity 0.5s ease-in 0.0s;
	&.magic{
		opacity: 1;
		transform: translateY(0px);
	}
}
