@import "../partials/base";

header{
	transition: background-color .3s;
	.g_nav{
		transition: transform .3s, opacity .3s;
		.contact_btn{
			a{
				transition: background-color .3s;
			}
		}
	}
	&.isActive{
		background-color: rgba($color: #000000, $alpha: .2);
		.g_nav{
			@include PC{
				transform: translateX(-1em);
			}
			.contact_btn{
				a{
					@include PC{
						background-color: transparent;
					}
				}
			}
		}
		&.active{
			transition: background-color 0s;
			background-color: #000000;
		}
	}
}

footer{
	.fixed_footer{
		transform: translateY(0%);
		transition: .4s;
	}
	&.isActive{
		.fixed_footer{
			transform: translateY(110%);
		}
	}
}
main{
	background-color: #fff;
	padding-top: 0;
}
#mv{
	width: 100%;
	padding-bottom: 100vh;
	position: relative;
	overflow: hidden;
	.visual{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		video{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.scrolldown1{
		position:absolute;
		left:50%;
		transform: translateX(-50%);
		bottom:50px;
		height:50px;
		width: 100%;
		span{
			position: absolute;
			top: -15px;
			left:50%;
			transform: translateX(-50%);
			color: #eee;
			font-size: 16px;
			letter-spacing: 0.05em;
		}
		::after{
			content: "";
			position: absolute;
			top: 20px;
			left: 50%;
			width: 1px;
			height: 30px;
			background: #eee;
			animation: pathmove 1.4s ease-in-out infinite;
			opacity:0;
		}
	}
	/*高さ・位置・透過が変化して線が上から下に動く*/
	@keyframes pathmove{
	  0%{
		height:0;
		top:20px;
		opacity: 0;
	  }
	  30%{
		height:30px;
		opacity: 1;
	  }
	  100%{
		height:0;
		top:50px;
		opacity: 0;
	  }
	}
	.catchC{
		position: absolute;
		z-index: 101;
		text-align: center;
		width: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		text-shadow: 2px 2px 8px #333;
		h2{
			@include fontResize (58,1280,1280,768);
			font-weight: bold;
			color: #fff;
			@include PC{
				padding-top: (55em/58);
				margin-left: 0.2em;
			}
			@include SP{
				@include fontResize (32,375,767);
				line-height: (46/32);
				transform: translateX(0.5em)
			}
		}
		.subttl{
			color: #fff;
			@include PC{
				@include fontResize (50,1280,1280,768);
				padding: (30em/50) 0 0;
			}
			@include SP{
				@include fontResize (24,375,767);
				padding: (10em/24) 0 0;
			}
		}
		.inner{
			display: inline-block;
			overflow: hidden;
			span{
				display: inline-block;
				transform: translateY(100%);
				transition: transform .6s ease-in-out;
				&.inner01{
					transition-delay: 1s;
				}
				&.inner02{
					transition-delay: 1s;
				}
				&.inner03{
					transition-delay: 1.8s;
				}
			}
		}
	}
	#opening{
		position: fixed;
		z-index: 100;
		width: 100%;
		height: 100%;
		background-color: #000;
		transition: opacity 1.8s ease-in-out;
		transition-delay: 2.8s;
	}
}
.page-loaded{
	#mv{
		.catchC{
			.inner{
				span{
					transform: translateY(0);
				}
			}
		}
		#opening{
			opacity: 0;
		}
	}
	&.opened{
		#mv{
			.catchC{
				z-index: 98;
			}
		}
	}
}

#home_lead{
	text-align: center;
	overflow: hidden;
	.lead_txt{
		@include fontResize (22,1280,1280,768);
		line-height: (38/22);
		padding-top: (80em/22);
		@include SP{
			padding-top: (40em/16);
			@include fontResize (16,375,767);
			line-height: (28/16);
		}
	}
}
#home_service{
	margin-top: (102em/10);
	@include SP{
		margin-top: (80em/10);
	}
	h2{
		text-align: center;
		@include fontResize (30,1280,1280,768);
		position: relative;
		@include SP{
			@include fontResize (24,375,767);
		}
		&::before{
			content: '';
			display: block;
			width: 100%;
			padding-bottom: (3em/30);
			background-color: #000;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
		}
		&::after{
			content: 'サービス';
			@include fontResize (14,1280,1280,768);
			position: absolute;
			top: (-20em/14);
			left: 50%;
			transform: translateX(-50%);
			@include SP{
				top: (-20em/16);
				@include fontResize (14,375,767);
			}
		}
		span{
			position: relative;
			z-index: 2;
			display: inline-block;
			padding: 0 (50em/30);
			background-color: #fff;
			&::before{
				content: '';
				display: block;
				width: (22em/30);
				padding-bottom: (13em/30);
				background-image: url(/assets/img/top/socket01.png);
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				top: 50%;
				left: -2px;
				transform: translateY(-50%);
			}
			&::after{
				content: '';
				display: block;
				width: (22em/30);
				padding-bottom: (13em/30);
				background-image: url(/assets/img/top/socket02.png);
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				top: 50%;
				right: -2px;
				transform: translateY(-50%);
			}
		}
	}
	.service_lead{
		max-width: 1280px;
		font-weight: bold;
		@include fontResize (40,1280,1280,768);
		line-height: (48/40);
		margin: (42em/40) auto 0;
		@include PC{
			padding-left: (370em/40);
		}
		@include SP{
			margin-top: (40em/28);
			text-align: center;
			padding: 0 (15em/28);
			@include fontResize (28,375,767);
		}
		.inner{
			@include SP{
				display: inline-block;
				text-align: left;
			}
		}
		.blue{
			color: $baseBlue;
		}
	}
	.service_lists{
		display: flex;
		flex-wrap: wrap;
		opacity: 1;
		transform: translateY(0);
		@include PC{
			max-width: 1280px;
			padding: 0 (120em/10) 0 (160em/10);
			margin: (20em/10) auto 0;
		}
		@include SP{
			padding: (20em/10) (8em/10) (20em/10) (8em/10);
		}
		li{
			opacity: 0;
			transition:opacity 0.5s ease-in 0.0s;
			margin-top: (30em/10);
			@include SP{
				margin-top: (15em/10);
			}
			&:not(:nth-child(4n)){
				@include PC{
					margin-right: (4em/10);
				}
			}
			&:nth-child(2n){
				@include SP{
					margin-left: (15em/10);
				}
			}
			a{
				display: block;
				width: (239em/10);
				padding-bottom: (236/239)*100%;
				position: relative;
				transition: .3s;
				@include SP{
					width: (167em/10);
				}
				&::before{
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					position: absolute;
					z-index: 1;
					background-image: url(/assets/img/top/frame.svg);
					background-size: contain;
					background-repeat: no-repeat;
				}
				&::after{
					@include PC{
						content: '';
						display: block;
						width: 98%;
						height: 100%;
						top: 0;
						left: 0;
						position: absolute;
						z-index: 0;
						opacity: 0;
						background: linear-gradient(to top, #ccc 0%, #fff 60%);
						transition: opacity .3s;
					}
				}
				.service_image{
					width: (205em/10);
					position: absolute;
					z-index: 3;
					top: (9em/10);
					left: (9em/10);
					@include SP{
						width: (139em/10);
					}
				}
				.service_name{
					font-weight: bold;
					@include fontResize (18,1280,1280,768);
					line-height: (24/18);
					position: absolute;
					z-index: 3;
					top: (180em/18);
					left: (9em/18);
					@include SP{
						top: (122em/13);
						left: (9em/13);
						@include fontResize (13,375,767);
					}
				}
				.service_more{
					@include fontResize (13,1280,1280,768);
					padding-right: (26em/13);
					position: absolute;
					z-index: 3;
					bottom: (20em/13);
					left: (160em/13);
					@include SP{
						padding-right: (18em/10);
						bottom: (15em/10);
						left: (112em/10);
						@include fontResize (10,375,767);
					}
					@include CUSTOM(374){
						left: (108em/10);
					}
					&::before{
						content: '';
						display: block;
						width: (24em/13);
						padding-bottom: (27em/13);
						background-image: url(/assets/img/common/light-ico-bk.svg);
						background-size: contain;
						background-repeat: no-repeat;
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
						margin-top: -5px;
						@include SP{
							width: (18em/10);
							padding-bottom: (21em/10);
						}
					}
				}
				&:hover{
					&:after{
						opacity: 1;
					}
					.service_more{
						@include PC{
							color: #FFF000;
						}
						&::before{
							@include PC{
								background-image: url(/assets/img/common/light-ico-active.svg);
							}
						}
					}
				}
			}
		}
		&.magic{
			li{
				opacity: 1;
				&:nth-child(2){
					transition-delay: .1s;
				}
				&:nth-child(3){
					transition-delay: .2s;
				}
				&:nth-child(4){
					transition-delay: .3s;
				}
				&:nth-child(5){
					transition-delay: .4s;
				}
				&:nth-child(6){
					transition-delay: .5s;
				}
				&:nth-child(7){
					transition-delay: .6s;
				}
			}
		}
	}
}
#home_about{
	margin-top: (100em/10);
	@include SP{
		margin-top: (80em/10);
	}
	h2{
		text-align: center;
		@include fontResize (30,1280,1280,768);
		position: relative;
		@include SP{
			@include fontResize (24,375,767);
		}
		&::before{
			content: '';
			display: block;
			width: 100%;
			padding-bottom: (3em/30);
			background-color: #000;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
		}
		&::after{
			content: '企業情報';
			@include fontResize (14,1280,1280,768);
			position: absolute;
			top: (-20em/14);
			left: 50%;
			transform: translateX(-50%);
			@include SP{
				top: (-20em/16);
				@include fontResize (14,375,767);
			}
		}
		span{
			position: relative;
			z-index: 2;
			display: inline-block;
			padding: 0 (50em/30);
			background-color: #fff;
			&::before{
				content: '';
				display: block;
				width: (22em/30);
				padding-bottom: (13em/30);
				background-image: url(/assets/img/top/socket01.png);
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				top: 50%;
				left: -2px;
				transform: translateY(-50%);
			}
			&::after{
				content: '';
				display: block;
				width: (22em/30);
				padding-bottom: (13em/30);
				background-image: url(/assets/img/top/socket02.png);
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				top: 50%;
				right: -2px;
				transform: translateY(-50%);
			}
		}
	}
	.about_lead{
		max-width: 1280px;
		font-weight: bold;
		@include fontResize (40,1280,1280,768);
		line-height: (48/40);
		margin: (42em/40) auto 0;
		@include PC{
			padding-left: (370em/40);
		}
		@include SP{
			margin-top: (40em/28);
			text-align: center;
			padding: 0 (15em/28);
			@include fontResize (28,375,767);
		}
		.inner{
			@include SP{
				display: inline-block;
				text-align: left;
			}
		}
		.blue{
			color: $baseBlue;
		}
	}
	.about_lists{
		opacity: 1;
		transform: translateY(0);
		max-width: 1280px;
		@include PC{
			display: flex;
			padding: 0 (140em/10) 0 (160em/10);
			margin: (46em/10) auto 0;
		}
		@include SP{
			padding: (50em/10) (15em/10) 0;
		}
		li{
			opacity: 0;
			transition:opacity 0.5s ease-in 0.0s;
			&:not(:last-child){
				@include PC{
					margin-right: (24em/10);
				}
				@include SP{
					margin-bottom: (30em/10);
				}
			}
			a{
				display: block;
				width: (304em/10);
				padding-bottom: (235/304)*100%;
				position: relative;
				transition: .3s;
				@include SP{
					width: 100%;
				}
				&::before{
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					position: absolute;
					z-index: 1;
					background-image: url(/assets/img/top/frame02.svg);
					background-size: contain;
					background-repeat: no-repeat;
				}
				&::after{
					content: '';
					display: block;
					width: 96%;
					height: (219/235)*100%;
					top: (15em/10);
					margin-left: auto;
					right: 0;
					position: absolute;
					z-index: 0;
					opacity: 0;
					background: linear-gradient(to bottom, #ccc 0%, #fff 60%);
					transition: opacity .3s;
				}
				.about_image{
					width: (279em/10);
					position: absolute;
					z-index: 3;
					top: (65em/10);
					left: (11em/10);
					@include SP{
						top: (72em/10);
						width: (322em/10);
					}
				}
				.about_name{
					font-weight: bold;
					@include fontResize (18,1280,1280,768);
					line-height: (24/18);
					position: absolute;
					z-index: 3;
					top: (30em/18);
					left: (36em/18);
					@include SP{
						@include fontResize (20,375,767);
						top: (30em/20);
						left: (36em/20);
					}
				}
				.about_more{
					@include fontResize (13,1280,1280,768);
					padding-right: (26em/13);
					position: absolute;
					z-index: 3;
					top: (40em/13);
					left: (242em/13);
					@include SP{
						@include fontResize (14,375,767);
						top: (43em/14);
						left: (270em/14);
					}
					&::before{
						content: '';
						display: block;
						width: (24em/13);
						padding-bottom: (27em/13);
						background-image: url(/assets/img/common/light-ico-bk.svg);
						background-size: contain;
						background-repeat: no-repeat;
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
						margin-top: -5px;
						@include SP{
							width: (24em/14);
							padding-bottom: (27em/14);
							margin-top: -(5em/14);
						}
					}
				}
				&:hover{
					&:after{
						@include PC{
							opacity: 1;
						}
					}
					.about_more{
						@include PC{
							color: #FFF000;
						}
						&::before{
							@include PC{
								background-image: url(/assets/img/common/light-ico-active.svg);
							}
						}
					}
				}
			}
		}
		&.magic{
			li{
				opacity: 1;
				&:nth-child(2){
					transition-delay: .1s;
				}
				&:nth-child(3){
					transition-delay: .2s;
				}
			}
		}
	}
}
#home_recruit{
	margin-top: (100em/10);
	@include SP{
		margin-top: (80em/10);
	}
	h2{
		text-align: center;
		@include fontResize (30,1280,1280,768);
		position: relative;
		@include SP{
			@include fontResize (24,375,767);
		}
		&::before{
			content: '';
			display: block;
			width: 100%;
			padding-bottom: (3em/30);
			background-color: #000;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: 1;
		}
		&::after{
			content: '採用情報';
			@include fontResize (14,1280,1280,768);
			position: absolute;
			top: (-20em/14);
			left: 50%;
			transform: translateX(-50%);
			@include SP{
				top: (-20em/16);
				@include fontResize (14,375,767);
			}
		}
		span{
			position: relative;
			z-index: 2;
			display: inline-block;
			padding: 0 (50em/30);
			background-color: #fff;
			&::before{
				content: '';
				display: block;
				width: (22em/30);
				padding-bottom: (13em/30);
				background-image: url(/assets/img/top/socket01.png);
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				top: 50%;
				left: -2px;
				transform: translateY(-50%);
			}
			&::after{
				content: '';
				display: block;
				width: (22em/30);
				padding-bottom: (13em/30);
				background-image: url(/assets/img/top/socket02.png);
				background-size: contain;
				background-repeat: no-repeat;
				position: absolute;
				top: 50%;
				right: -2px;
				transform: translateY(-50%);
			}
		}
	}
	.recruit_lead{
		max-width: 1280px;
		text-align: left;
		font-weight: bold;
		@include fontResize (40,1280,1280,768);
		line-height: (48/40);
		margin: (42em/40) auto 0;
		@include PC{
			padding-left: (370em/40);
		}
		@include SP{
			margin-top: (40em/30);
			text-align: center;
			padding: 0 (15em/30);
			@include fontResize (28,375,767);
		}
		.inner{
			@include SP{
				display: inline-block;
				text-align: left;
			}
		}
		.blue{
			color: $baseBlue;
		}
	}
	.recruit_wrap{
		width: (244em/10);
		margin: (36em/10) auto 0;
		position: relative;
		@include SP{
			margin: (35em/10) auto 0;
		}
		&::before{
			content: '';
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: #000;
			top:(8em/10);
			left:(8em/10);
			z-index: 0;
		}
		.recruit_link{
			position: relative;
			z-index: 1;
			a{
				display: block;
				padding: (50em/10) (16em/10) (14em/10);
				background-color: #fff;
				border: 3px solid #000;
				position: relative;
				transition: .3s;
				&::before{
					content: '';
					display: block;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: (28em/10) (28em/10) 0 0;
					border-color: #000000 transparent transparent transparent;
					position: absolute;
					z-index: 1;
					top: 0;
					left: 0;
					transition: .3s;
				}
				&::after{
					content: '';
					display: block;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 0 (28em/10) (28em/10);
					border-color: transparent transparent #000000 transparent;
					position: absolute;
					z-index: 1;
					bottom: 0;
					right: 0;
					transition: .3s;
				}
				.recruit_title{
					@include fontResize (30,1280,1280,768);
					text-align: center;
					padding-bottom: (15em/30);
					border-bottom: 3px solid #000;
					margin-bottom: (34em/30);
					font-weight: bold;
					position: relative;
					z-index: 1;
					transition: .3s;
					@include SP{
						margin-bottom: (38em/26);
						padding-bottom: (19em/26);
						@include fontResize (26,375,767);
					}
				}
				.recruit_more{
					@include fontResize (20,1280,1280,768);
					text-align: center;
					position: relative;
					z-index: 1;
					@include SP{
						@include fontResize (14,375,767);
					}
					span{
						display: inline-block;
						padding-right: (36em/20);
						position: relative;
						@include SP{
							padding-right: (25em/14);
						}
						&::before{
							content: '';
							display: block;
							width: (36em/20);
							padding-bottom: (41em/20);
							background-image: url(/assets/img/common/light-ico-bk.svg);
							background-size: contain;
							background-repeat: no-repeat;
							position: absolute;
							bottom: 0;
							right: 0;
							@include SP{
								width: (25em/14);
								padding-bottom: (30em/14);
							}
						}
					}
				}
				&:hover{
					@include PC{
						transform: translate((8em/10),(8em/10));
						background-color: #000;
					}
					&::before{
						@include PC{
							border-color: #fff transparent transparent transparent;
						}
					}
					&::after{
						@include PC{
							border-color: transparent transparent #fff transparent;
						}
					}
					.recruit_title{
						@include PC{
							color: #fff;
							border-bottom: 3px solid #fff;
						}
					}
					.recruit_more{
						@include PC{
							color: #FFF000;
						}
						span{
							&::before{
								@include PC{
									background-image: url(/assets/img/common/light-ico-active.svg);
								}
							}
						}
					}
				}
			}
		}
	}
}
