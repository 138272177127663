$breakpoint: 767px;
$baseBlue:#00A7E9;

@mixin PC($minwidth:$breakpoint + 1) {
	@media screen and (min-width:$minwidth) {
		@content;
	}
}

@mixin SP($maxwidth:$breakpoint) {
	@media screen and (max-width:$maxwidth) {
		@content;
	}
}

@mixin CUSTOM($bp:$breakpoint) {
	@media screen and (max-width:$bp+'px') {
		@content;
	}
}

/**
 * フォントサイズの可変
 *
 * @param {number} $fontsize 基準となるフォントサイズ
 * @param {number} $basewidth 基準となる幅
 * @param {number} $maxwidth フォントサイズの拡大をとめる幅（未指定時は上限なし）
 * @param {number} $minwidth フォントサイズの縮小をとめる幅（未指定時は下限なし）
 */
 @mixin fontResize ($fontsize, $basewidth, $maxwidth:0, $minwidth:0) {
	font-size: ($fontsize * (1000 / $basewidth) / 10) + 0vw;

	@if $maxwidth != 0 {
		@media screen and (min-width:#{$maxwidth}px) {
			font-size: $fontsize * ($maxwidth / $basewidth) + 0px;
		}
	}
	@if $minwidth != 0 {
		@media screen and (max-width:#{$minwidth}px) {
			font-size: $fontsize * ($minwidth / $basewidth) + 0px;
		}
	}
}
